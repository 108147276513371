<template>
  <div class="main">
    <div class="header">
      <img class="header-logo-ci" src="@/assets/image/zhfe/zhfCI.png" />
      <img class="header-logo" src="@/assets/image/zhfe/zhfelogo.png" />
      <span class="header-txt">智惠妇儿</span>
      <!-- 关闭 刷新 -->
      <div class="idx-middlesx-box3">
        <div class="idx-middle-1">
          <img
            class="fh"
            src="@/assets/image/zhfe/return.png"
            @click="goBack"
          />
          <!-- <img
            class="fh"
            src="@/assets/image/zhfe/icon/gb.png"
            v-show="gb"
            @click="close"
          /> -->
          <img
            class="sx"
            src="@/assets/image/zhfe/icon/sx.png"
            alt=""
            @click="sx"
          />
        </div>
      </div>
    </div>
    <!-- 时间 -->
    <div class="time-box">
      <Date></Date>
    </div>
    <div class="page_title">
      <div class="title_text">光荣榜管理</div>
    </div>
    <div class="content">
      <div class="conter_header">
        <div class="form_box">
          <el-form
            ref="ruleForm"
            :inline="true"
            :model="queryParam"
            class="demo-form-inline"
          >
            <el-form-item label="" prop="remark">
              <el-input
                v-model="queryParam.remark"
                placeholder="请输入搜索内容"
              ></el-input>
            </el-form-item>
            <el-form-item label="" prop="type">
              <el-select v-model="queryParam.type" placeholder="请选择类别">
                <el-option
                  v-for="(item, index) in zwList"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <div class="btn_box">
                <div @click="onQuery" class="query_btn">查询</div>
                <div @click="onReset" class="reset_btn">重置</div>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div v-if="isEditFlag" class="btn_box">
          <div @click="openDialog({ data: {}, type: 'add' })" class="">
            新增
          </div>
          <div @click="importBtn" class="">导入</div>
        </div>
      </div>
      <div
        v-loading="loading"
        element-loading-text="加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        class="table_box"
      >
        <tableList
          :isEditFlag="isEditFlag"
          @checkImg="checkImg"
          :tableData="tableData"
          @openDialog="openDialog"
        ></tableList>
      </div>
      <div class="pagination_box">
        <el-pagination
          :page-size="queryParam.pageSize"
          :current-page="queryParam.pageNum"
          background
          layout="total, sizes, prev, pager, next"
          :total="total"
          @current-change="changeNum"
          @size-change="changeSize"
        >
        </el-pagination>
      </div>
    </div>
    <dialogAlert
      :dialogVisible="dialogVisible"
      :dialogData="dialogData"
      @closeDialog="delCloseDialog"
      @determine="determine"
      :dialogTitle="dialogTitle"
    >
      <span slot="txt">{{ delDataTxt }}</span>
    </dialogAlert>
    <imgDialog
      :checkImgUrl="checkImgUrl"
      v-if="imgDialogShow"
      :imgDialogShow="imgDialogShow"
      @closeImgDialog="closeImgDialog"
    ></imgDialog>
    <addEditDialog
      :addEditDialogFlag="addEditDialogFlag"
      :dialogData="dialogData"
      @closeAddEditDialog="closeAddEditDialog"
      @getList="getList"
    ></addEditDialog>
    <importDialog
      :importDialog="importDialog"
      @importClose="importClose"
      @importSuccess="importSuccess"
    ></importDialog>
  </div>
</template>
<script>
import { resetPage } from "@/utils/index.js";
import Date from "@/views/commonComponents/date.vue";
import dialogAlert from "@/views/homeImg/components/dialogAlert.vue";
import tableList from "./components/tableList.vue";
import imgDialog from "./components/imgDialog.vue";
import importDialog from "./components/importDialog.vue";
import addEditDialog from "./components/addEditDialog.vue";
import { getGrbList, deleteGrb } from "@/api/zhfeAward";
export default {
  components: {
    Date,
    tableList,
    imgDialog,
    dialogAlert,
    addEditDialog,
    importDialog,
  },
  data() {
    return {
      addEditDialogFlag: false,
      checkImgUrl: "",
      isEditFlag: false,
      imgDialogShow: false,
      loading: false,
      queryParam: {
        pageNum: 1,
        pageSize: 10,
        remark: "",
        type: "",
      },
      tableData: [],
      total: 0,
      zwList: [
        { label: "个人", value: "个人" },
        { label: "家庭", value: "家庭" },
        { label: "单位", value: "单位" },
      ],
      dialogVisible: false,
      delDataTxt: "",
      dialogData: {},
      importDialog: false,
      dialogTitle: "系统提示",
    };
  },
  watch: {},
  async created() {
    if (this.$cookies.get("pass") && this.$cookies.get("pass") == "fulian456") {
      this.isEditFlag = true;
    }
    await this.getList();
  },
  mounted() {},
  methods: {
    closeAddEditDialog(val) {
      this.addEditDialogFlag = val;
    },
    importBtn() {
      this.importDialog = true;
    },
    importSuccess() {
      this.importDialog = false;
      this.queryParam.pageNum = 1;
      this.getList();
    },
    importClose(val) {
      this.importDialog = val;
    },
    openDialog(obj) {
      switch (obj.type) {
        case "add":
          this.onAdd();
          break;
        case "edit":
          this.onEdit(obj.data);
          break;
        case "delete":
          console.log(obj);
          this.onDelete(obj.data);
          break;
      }
    },
    onAdd() {
      this.dialogData = {
        name: "",
        honor: "",
        type: "",
        honorDesc: "",
        briefStory: "",
        sort: "",
      };
      this.addEditDialogFlag = true;
    },
    onEdit(row) {
      this.dialogData = JSON.parse(JSON.stringify(row));
      this.addEditDialogFlag = true;
    },
    onDelete(row) {
      this.dialogData = row;
      this.delDataTxt = `是否确认删除名称为 "${row.name}" 的数据项吗?`;
      this.dialogVisible = true;
    },
    delCloseDialog() {
      this.dialogVisible = false;
    },
    determine() {
      deleteGrb(this.dialogData.id)
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("删除成功");
            this.dialogVisible = false;
            this.queryParam.pageNum = resetPage(
              this.queryParam.pageNum,
              this.queryParam.pageSize,
              this.total,
              1
            );
            this.getList();
          }
        })
        .catch((err) => {
          this.$message.info(res.msg);
        });
    },
    closeImgDialog() {
      this.checkImgUrl = "";
      this.imgDialogShow = false;
    },
    checkImg(row) {
      console.log(row.personalPhoto);
      this.checkImgUrl = row.personalPhoto;
      this.imgDialogShow = true;
    },
    onQuery() {
      this.queryParam.pageNum = 1;
      this.getList();
    },
    onReset() {
      this.queryParam = {
        pageNum: 1,
        pageSize: 10,
        remark: "",
        type: "",
      };
      this.getList();
    },
    async getList() {
      let { rows, total } = await getGrbList(this.queryParam);
      this.total = total;
      this.tableData = rows;
    },
    changeNum(val) {
      this.queryParam.pageNum = val;
      this.getList();
    },
    changeSize(val) {
      this.queryParam.pageSize = val;
      this.queryParam.pageNum = 1;
      this.getList();
    },
    close() {
      this.$cookies.remove("pass"); // return this
      this.$router.go(0); //刷新当前页面
    },
    sx() {
      this.$router.go(0); //刷新当前页面
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang='scss' scoped>
@import "./index.scss";
</style>