import request from '@/utils/request'
// 获取光荣榜列表
export function getGrbList(query) {
  return request({
    url: `/zhfe/grb/list`,
    method: 'get',
    params: query
  })
}

// 获取光荣榜详情
export function getGrbDetail(id) {
  return request({
    url: `/zhfe/grb/${id}`,
    method: 'get',
  })
}

// 新增光荣榜
export function addGrb(data) {
  return request({
    url: `/zhfe/grb`,
    method: 'post',
    data: data,
  })
}

// 修改光荣榜
export function editGrb(data) {
  return request({
    url: `/zhfe/grb`,
    method: 'put',
    data: data,
  })
}

// 删除光荣榜
export function deleteGrb(id) {
  return request({
    url: `/zhfe/grb/${id}`,
    method: 'delete',
  })
}

// 获取光荣榜导入模板
export function getGrbExportFile() {
  return request({
    url: `/zhfe/grb/importTemplate`,
    method: 'get'
  })
}

// 导入光荣榜
export function exportGrbXlsx(query) {
  return request({
    url: `/zhfe/grb/importGRB`,
    method: 'post',
    data: query
  })
}

// 获取近年获奖情况导入模板
export function getJnhjqkExportFile() {
  return request({
    url: `/szdt/data/excel/winner/exportTemplate`,
    method: 'get'
  })
}

// 导入近年获奖情况
export function exportJnhjqkXlsx(query) {
  return request({
    url: `/szdt/data/excel/winner/importData`,
    method: 'post',
    data: query
  })
}

// 获取培育孵化导入模板
export function getPyfhExportFile() {
  return request({
    url: `/szdt/data/excel/incubation/exportTemplate`,
    method: 'get'
  })
}

// 导入培育孵化
export function exportPyfhkXlsx(query) {
  return request({
    url: `/szdt/data/excel/incubation/importData`,
    method: 'post',
    data: query
  })
}