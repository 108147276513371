<template>
  <div v-if="addEditDialogFlag" class="dialog_cover_cloth">
    <div class="dialog_box">
      <div class="box_content">
        <div class="content_box">
          <div class="box_header">
            <div class="title">光荣榜信息填写</div>
            <div @click="closeAddEditDialog" class="close_btn"></div>
          </div>
          <div class="box_conter">
            <el-form
              :model="dialogData"
              :rules="rules"
              ref="ruleForm"
              label-width="6.25vw"
              class="demo-ruleForm"
            >
              <el-row>
                <el-col :span="12">
                  <el-form-item label="名称" prop="name">
                    <el-input v-model="dialogData.name"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="简略荣誉" prop="honor">
                    <el-input v-model="dialogData.honor"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="类型" prop="type">
                    <el-select
                      v-model="dialogData.type"
                      placeholder="请选择类型"
                    >
                      <el-option
                        v-for="(item, index) in typeArr"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="详细荣誉" prop="honorDesc">
                    <el-input v-model="dialogData.honorDesc"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-form-item label="简要事迹" prop="briefStory">
                <el-input
                  type="textarea"
                  v-model="dialogData.briefStory"
                ></el-input>
              </el-form-item>
              <el-form-item label="排序" prop="sort">
                <el-input-number
                  v-model="dialogData.sort"
                  :min="1"
                  :precision="0"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="图片" prop="personalPhoto">
                <el-upload
                  class="avatar-uploader"
                  :action="uploadAction"
                  :show-file-list="false"
                  :on-error="imgerror"
                  :on-success="imgsuccess"
                  :before-upload="beforeAvatarUpload"
                >
                  <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
            </el-form>
          </div>
          <div class="box_footer">
            <div class="btn_box">
              <div @click="closeAddEditDialog" class="close_btn">取消</div>
              <div
                :loading="submitBtnLoading"
                @click="submitBtn"
                class="submit_btn"
              >
                {{ this.dialogData.id ? "确定修改" : "确定新增" }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { addGrb, editGrb } from "@/api/zhfeAward";
export default {
  props: {
    addEditDialogFlag: Boolean,
    dialogData: Object,
  },
  components: {},
  data() {
    return {
      submitBtnLoading: false,
      typeArr: [
        { label: "个人", value: "个人" },
        { label: "家庭", value: "家庭" },
        { label: "单位", value: "单位" },
      ],
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        honor: [{ required: true, message: "请输入简略荣耀", trigger: "blur" }],
        type: [{ required: true, message: "请选择类别", trigger: "change" }],
        honorDesc: [
          { required: true, message: "请输入详细荣耀", trigger: "blur" },
        ],
        sort: [{ required: true, message: "请输入排序", trigger: "change" }],
      },
      uploadAction: "/prod-api/common/upload",
      imageUrl: "",
      loading: "",
    };
  },
  watch: {
    dialogData: {
      handler(newData, oldData) {
        console.log(newData, oldData);
        if (newData.personalPhoto) {
          this.imageUrl = newData.personalPhoto;
        } else {
          this.imageUrl = "";
        }
      },
      immediate: true, // 是否进行第一次监测
      deep: true, // 深度监测,监测引用数据类型是必须深度监测
    },
  },
  created() {
    this.dialogData.sort = this.dialogData.sort ? this.dialogData.sort : 1;
  },
  mounted() {},
  methods: {
    closeAddEditDialog() {
      this.imageUrl = "";
      this.$refs.ruleForm.resetFields();
      this.$emit("closeAddEditDialog", false);
    },
    submitBtn() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.submitBtnLoading = true;
          let requestName = this.dialogData.id ? editGrb : addGrb;
          console.log(this.dialogData);
          requestName(this.dialogData)
            .then((res) => {
              if (res.code == 200) {
                this.$message.success(
                  `${this.dialogData.id ? "修改成功" : "添加成功"}`
                );
                this.submitBtnLoading = false;
                this.closeAddEditDialog();
                this.$emit("getList");
              }
            })
            .catch((err) => {
              this.$message.info(res.msg);
              this.submitBtnLoading = false;
            });
        }
      });
    },
    // 上传失败
    imgerror() {
      this.$message({
        type: "error",
        message: "上传失败",
      });
      this.loading.close();
    },
    // 图片上传成功
    imgsuccess(res, file) {
      if (res.code == 200) {
        this.imageUrl = URL.createObjectURL(file.raw);
        this.dialogData.personalPhoto = res.fileName;
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
      this.loading.close();
    },
    // 上传中
    beforeAvatarUpload(file) {
      this.loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 < 150;

      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG/PNG 格式");
      }
      //  else if (!isLt2M) {
      //   this.$message.error("上传图片大小不能超过 150kb");
      // }
      this.loading.close();
      return isJPG;
      // return isJPG && isLt2M;
    },
  },
};
</script>
<style lang='scss' scoped>
.dialog_cover_cloth {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 100;
  top: 0;
  .dialog_box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .box_content {
      background: transparent;
      width: 48vw;
      .bg_img {
        width: 48vw;
        img {
          width: 100%;
        }
      }
      .content_box {
        color: #fff;
        width: 48vw;
        line-height: 2vw;
        background: url("../../../assets/image/dialogBg.png") no-repeat;
        background-size: 100% 100%;
        min-height: 55vh;
        .box_header {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          position: relative;
          margin-bottom: 1vw;
          .title {
            color: #fff;
            font-weight: bold;
            font-size: 0.9vw;
            background: #cd80a2;
            border-bottom-left-radius: 0.5vw;
            border-bottom-right-radius: 0.5vw;
            border: 0.1vw solid;
            padding: 0 2vw;
          }
          .close_btn {
            background: url("../../../assets/image/zhfe/dialogClose.png")
              no-repeat;
            background-size: 100% 100%;
            position: absolute;
            width: 2vw;
            height: 2vw;
            top: -1vw;
            right: -1vw;
            cursor: pointer;
            // opacity: 0;
            padding: 0.5vw;
          }
        }
        .box_conter {
          padding: 0 2vw;
          ::v-deep {
            .avatar-uploader .el-upload {
              border: 1px dashed #d9d9d9;
              border-radius: 6px;
              cursor: pointer;
              position: relative;
              overflow: hidden;
            }
            .avatar-uploader .el-upload:hover {
              border-color: #409eff;
            }
            .avatar-uploader-icon {
              font-size: 28px;
              color: #8c939d;
              width: 9vw;
              height: 9vw;
              line-height: 178px;
              text-align: center;
            }
            .avatar {
              width: 9vw;
              height: 9vw;
              display: block;
            }
            .el-form-item {
              margin-bottom: 1.15vw;
            }
            .el-form-item__label {
              color: rgba(255, 212, 227, 1);
              font-size: 0.73vw;
              line-height: 2.1vw;
              padding: 0 0.62vw 0 0;
            }
            .el-textarea__inner,
            .el-input__inner {
              color: rgba(255, 212, 227, 1);
              background-color: transparent;
              &::placeholder {
                color: rgba(255, 212, 227, 1);
              }
            }
            .el-select .el-input .el-select__caret {
              color: rgba(255, 212, 227, 1);
            }
            .el-input__prefix,
            .el-input__suffix {
              color: rgba(255, 212, 227, 1);
            }
            .el-textarea {
              font-size: 0.75vw;
            }
            .el-form-item__content {
              line-height: 2vw;
              font-size: 0.75vw;
            }
            .el-input {
              font-size: 0.73vw;
            }
            .el-input--suffix .el-input__inner {
              padding-right: 1.6vw;
            }
            .el-input__inner {
              height: 2.2vw;
              line-height: 2.2vw;
              // padding: 0 0 0 1.5vw;
            }
            .el-select .el-input .el-select__caret {
              font-size: 0.73vw;
            }
            .el-date-editor.el-input,
            .el-date-editor.el-input__inner {
              width: 100%;
            }
            .el-select {
              width: 100%;
            }
            .el-input-number__decrease,
            .el-input-number__increase {
              background: transparent;
              color: rgb(255, 212, 227);
              width: 2.05vw;
              font-size: 0.7vw;
            }
            .el-input {
              font-size: 0.7vw;
            }
            .el-input-number {
              width: 7vw;
              line-height: 2vw;
            }
            .el-input__icon {
              line-height: 0;
            }
          }
        }
        .box_footer {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 1vw;
          color: #ffd4e4;
          .btn_box {
            display: flex;
            div {
              border: 0.1vw solid #ffd4e4;
              margin-right: 1vw;
              padding: 0.1vw 4vw;
              cursor: pointer;
              font-size: 0.8vw;
            }
          }
        }
      }
    }
  }
}
</style>