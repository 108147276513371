<template>
  <!-- <div @click="closeImgDialog" v-if="imgDialogShow" class="dialog_cover_cloth">
    <div class="dialog_box">
      <div class="box_content">
        <img :src="checkImgUrl" alt="" />
      </div>
    </div>
  </div> -->
  <div class="dialog_cover_cloth">
    <div class="dialog_box">
      <div class="box_content">
        <div class="content_box">
          <div class="box_header">
            <div class="title">查看图片</div>
            <div @click="closeImgDialog" class="close_btn"></div>
          </div>
          <div class="box_conter">
            <img v-if="checkImgUrl" :src="checkImgUrl" alt="" />
            <!-- <img src="../../../assets/image/zhfe/dialogBg.png" alt="" /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: { imgDialogShow: Boolean, checkImgUrl: String },
  components: {},
  data() {
    return {};
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    closeImgDialog() {
      this.$emit("closeImgDialog");
    },
  },
};
</script>
<style lang='scss' scoped>
.dialog_cover_cloth {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 100;
  top: 0;
  .dialog_box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    // align-items: center;
    margin-top: 5vh;
    .box_content {
      // background: #fff;
      // width: 50%;
      // height: 50%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .content_box {
      color: #fff;
      width: 48vw;
      line-height: 2vw;
      background: url("../../../assets/image/dialogBg.png") no-repeat;
      background-size: 100% 100%;
      min-height: 55vh;
      .box_header {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        position: relative;
        margin-bottom: 1vw;
        .title {
          color: #fff;
          font-weight: bold;
          font-size: 0.9vw;
          background: #cd80a2;
          border-bottom-left-radius: 0.5vw;
          border-bottom-right-radius: 0.5vw;
          border: 0.1vw solid;
          padding: 0 2vw;
        }
        .close_btn {
          background: url("../../../assets/image/zhfe/dialogClose.png")
            no-repeat;
          background-size: 100% 100%;
          position: absolute;
          width: 2vw;
          height: 2vw;
          top: -1vw;
          right: -1vw;
          cursor: pointer;
          // opacity: 0;
          padding: 0.5vw;
        }
      }
      .box_conter {
        padding: 2vw;
        width: 100%;
        height: 100%;
        img {
          width: 44vw;
          height: 50vh;
        }
      }
    }
  }
}
</style>